import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Professional Website Design and Development - Websuasion"
        ogDescription="Are you looking for professional website design from a 100% Atlanta-based team of designers, programmers, and content producers? Check out our case studies."
        image="/images/case-studies/professional-website-design-case-studies.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Professional Website Design and Development</h1>
            <p>
							Are you looking for fractional and white-label marketing services including professional website design from a 100% Atlanta-based team of designers, programmers, and content producers? We are locally accessible and <em>never</em> outsource. Our client case studies below highlight our clients from all over the country. But, we especially love working with marketing agencies and mid-sized businesses in Metro-Atlanta.
						</p>
						<FormWrapper
              modal
              buttonProps={{
                content: "Schedule An Introductory Call",
                size: "tiny",
                icon: "calendar alternate outline",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/case-studies/professional-website-design-case-studies.png" className="rounded-image" alt="Professional Website Design and Development Case Studies" />
          </div>
        }
      />
			<PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/case-studies/custom-web-application-experience.png" className="rounded-image" alt="Professional Website Design and Development Experience" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>Experience</h2>
							<p>
								Experience is important when selecting the right white label or fractional marketing team. <a href="/digital-marketing-agency-atlanta/">We have over 28 years of experience in marketing, web development, and content production</a>. Our founder, J. Ryan Williams, mentors each staff member to work "the websuasion way." Those years of training yield a team who know their workflow inside and out. It's likely we already have a solution to your issues.
							</p>
							<h2><a href="/development/software-development-company/">Custom Web Applications</a></h2>
							<p>
								You've built a successful business, but you're struggling to expand. You need <a href="/development/software-development-company/">full-stack web development</a> by an experienced team so your business will scale. Are you reliant on spreadsheets to generate client deliverables? Moving tasks to the cloud can increase your speed and output. Redundant data entry across many systems is a strain on your administration cost. Custom web development, collecting metrics, and data visualization can open up new worlds of opportunity.
							</p>
							<h2><a href="https://websuasion.com/digital-marketing-agency-branding-firm/">Marketing Websites</a></h2>
							<p>
								You need a <em>professional website design</em> that achieves your lead generation goals. An optimized <a href="/marketing-technology/">marketing technology stack</a> allows you to execute digital marketing campaigns that achieve your goals. Device-responsive websites look great on desktops, laptops, mobile phones, and tablets. Your website will channel targeted prospects through lead funnels to maximize sales conversions.
		          </p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
					centered
	        text={
	          <div>
	            <h2>Client Case Studies</h2>
	          </div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block grid-image">
            <a href="/case-studies/atlanta-seo-website-design/"><img src="/images/case-studies/atlanta-seo-website-design.jpg" alt="Atlanta SEO Website Design - LTCPC" width="300" /></a>
            <h3><a href="/case-studies/atlanta-seo-website-design/">The Long Term Care Planning Group</a></h3>
            <p>
							We enabled The Long Term Care Planning Group to expand their organic SEO reach and establish a lead generation system connecting to their Saleforce CRM.
            </p>
            <p>
							<strong>Provided SEO Keyword Research and Strategy, Web Design, Video Production, Saleforce Integration, and Ongoing Support.</strong>
            </p>
          </div>
        }
        textCenter={
          <div className="general-block grid-image">
            <a href="/case-studies/app-development-atlanta/"><img src="/images/case-studies/midtown-atlanta-application-development.jpg" alt="Midtown Atlanta Application Development Sionic Mobile" width="300" /></a>
            <h3><a href="/case-studies/app-development-atlanta/">Sionic Mobile</a></h3>
            <p>
							We enabled Sionic Mobile to achieve their web portal development and web design goals.
            </p>
            <p>
							<strong>Provided Web Portal Development, Website Design, and Ongoing Support.</strong>
            </p>
          </div>
        }
        textRight={
          <div className="general-block grid-image">
            <a href="/case-studies/web-developer-irvine-ca/"><img src="/images/case-studies/irvine-ca-application-development-ivantis.jpg" alt="Irvine CA Application Development Ivantis" width="620" /></a>
            <h3><a href="/case-studies/web-developer-irvine-ca/">Ivantis Inc.</a></h3>
            <p>
							We designed the Spectrum system to enable Ivantis to access critical data about their Hydrus device.
            </p>
            <p>
							<strong>Provided Application Architecture, Web Portal Development, and Ongoing Support.</strong>
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block grid-image">
            <a href="/case-studies/app-development-denver-colorado/"><img src="/images/case-studies/denver-colorado-app-development.jpg" alt="Denver Colorado Mobile App Development - Ivantis" width="300" /></a>
            <h3><a href="/case-studies/app-development-denver-colorado/">Intrix Technology</a></h3>
            <p>
							We enabled Intrix Technology to achieve their mobile app, API, and web development goals.
            </p>
            <p>
							<strong>Provided iOS and Android App, Web Portals, and Ongoing Support.</strong>
            </p>
          </div>
        }
        textCenter={
          <div className="general-block grid-image">
            <a href="/case-studies/buckhead-web-development-atlanta/"><img src="/images/case-studies/buckhead-web-development-atlanta.jpg" alt="Buckhead Web Development Atlanta - Day1" width="300" /></a>
            <h3><a href="/case-studies/buckhead-web-development-atlanta/">Day1</a></h3>
            <p>
							We enabled Day1 to achieve their web development goals.
            </p>
            <p>
							<strong>Provided Website Design, Web Development, Photography, FTP Server, Logo Design, and Ongoing Support.</strong>
            </p>
          </div>
        }
        textRight={
          <div className="general-block grid-image">
            <a href="/case-studies/web-design-pinewood-trilith-atlanta-studios-hanna-brothers/"><img src="/images/case-studies/web-design-pinewood-atlanta-studios.jpg" alt="Web Design Pinewood Atlanta Studios" width="620" /></a>
            <h3><a href="/case-studies/web-design-pinewood-trilith-atlanta-studios-hanna-brothers/">Hanna Brothers</a></h3>
            <p>
							We enabled Hanna Brothers to achieve their web design goals.
            </p>
            <p>
							<strong>Provided Website Design, Photography, and Ongoing Support.</strong>
            </p>
          </div>
        }
      />
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="general-block grid-image">
            <a href="/case-studies/fayetteville-ga-web-design/"><img src="/images/case-studies/one-more-generation.jpg" alt="Fayetteville GA Web Design" width="300" /></a>
            <h3><a href="/case-studies/fayetteville-ga-web-design/">One More Generation</a></h3>
            <p>
							We enabled One More Generation to achieve their web design, and web app development goals.
            </p>
            <p>
							<strong>Provided Website Design, Photography, and Ongoing Support.</strong>
            </p>
          </div>
        }
        textCenter={
          <div className="general-block grid-image">
            <a href="/case-studies/atlanta-ga-web-designer/"><img src="/images/case-studies/midtown-atlanta-web-design-woodruff-arts.jpg" alt="Midtown Atlanta Web Design Woodruff Arts" width="300" /></a>
            <h3><a href="/case-studies/atlanta-ga-web-designer/">Woodruff Arts</a></h3>
            <p>
							We enabled Woodruff Arts to achieve their web design goals.
            </p>
            <p>
							<strong>Provided Website Design.</strong>
            </p>
          </div>
        }
        textRight={
          <div className="general-block grid-image">
            <a href="/case-studies/roswell-ga-web-developer/"><img src="/images/case-studies/roswell-ga-web-developer-bck-case-study.jpg" alt="Roswell GA Web Developer - BCK" width="620" /></a>
            <h3><a href="/case-studies/roswell-ga-web-developer/">BCK Consulting</a></h3>
            <p>
							We enabled BCK Consulting to achieve their web development and digital transformation goals.
            </p>
            <p>
							<strong>Provided Website Design, Web Portal Development, Photography, Logo Design, Webinar Video Production and Ongoing Support.</strong>
            </p>
          </div>
        }
      />
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Search Engine Optimization In Professional Website Design</h2>
							<p>
								Your website must speak to customers and search engines. Make sure you launch with marketing content <a href="/content-marketing-strategy/">optimized for search</a>. A content marketing plan provides detailed topical and technical guidance. You can publish yourself with a content management system. Or, we can manage and distribute content for you.
							</p>
							<h2>The Role of Content Development in Professional Website Design</h2>
							<p>
								Internet Marketing revolves around two primary activities: paid advertising and organic search results. Both need optimized marketing assets which convert prospects to sales. Targeted articles are the essence of content marketing and advertising funnels. Custom photography individualizes your brand. Differentiate your business from the bland stock photography image of your competitors. And nothing establishes like and trust with your customers more than video. Well-produced videos are valuable resources for viral shares, ads, and webinars.
							</p>
							<h2>Managed Hosting and Support</h2>
							<p>
								Professional website design needs managed-VPS cloud hosting. A well-configured VPS will get the best performance out of your web applications. A VPS provides exclusive resources configured for your performance needs. You get the stability of the Ubuntu Linux, NGINX web server, and PostgreSQL database. Proper DNS and server configuration protects your server. This includes SSL Certificates, SSH key authentication, and firewalls. But new attacks pop up often. Continuous maintenance reduces vulnerability through security updates and database backup. Uptime monitoring tells us the minute there is an issue with your website.
		          </p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
				<TextBlock
	        textPadded
	        textLeft={
	          <div>
				      <div className='player-wrapper'>
				        <ReactPlayer
				          className='react-player'
				          url='https://www.youtube.com/watch?v=-YTR647HTBM'
				          width='100%'
				          height='100%'
				        />
							</div>
	          </div>
	        }
	        textRight={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
